import React from "react";

import Rodo from '../components/Rodo/Rodo';
import Template from '../components/Template/Template';

const RodoPage = () => {


    return (
        <Template path="/rodo">
            <Rodo />
        </Template>
    )
}

export default RodoPage
